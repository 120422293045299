<template>
  <div class="app charts">
    <Navigation/>
    <PanelSidebar/>
    <div class="panel-content charts-content" ref="contentRef">
      <Head :title="title" :type="type" :docPath="docPath" :placeholder1="placeholder1" :placeholder2="placeholder2" @click1="top10Total" @click2="top10Day" @submit="daily"/>
      <div class="charts-content-in">
        <div class="chart-title">
          <p class="text">{{ t('charts.title', { title: chartTitle, total: total }) }}</p>
          <span class="switch" v-if="list !== null"><em @click="swt()">{{ t('charts.switch') }}</em></span>
        </div>
        <div class="chart" ref="chartRef" :style="{height: `${chartAreaHeight}px`, display: list === null ? 'none' : 'block'}"></div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import Head from '../../components/PanelHead.vue'
import Navigation from '../../components/PanelNav.vue'
import PanelSidebar from '../../components/PanelSidebar.vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { onMounted } from '@vue/runtime-core'
import { useDialog } from '../../utils/dialog.js'
import { reactive, ref, toRefs } from '@vue/reactivity'
import { top10DayApi, top10TotalApi, groupDailyApi } from '../../api/index.js'

export default {
  name: 'Charts',
  components: {
    Head,
    Navigation,
    PanelSidebar
  },
  setup() {
    const { t } = useI18n()
    const contentRef = ref(null)
    const chartRef = ref(null)
    const Dialog = useDialog()
    const Route = useRoute()
    const data = reactive({
      // 页头
      title: '',
      type: '',
      docPath: '',
      placeholder1: '',
      placeholder2: '',

      // 当前 url 路径
      uri: Route.path,

      // 动态高度
      chartAreaHeight: '',

      // 页面数据
      chartTitle: '',
      chartType: 'pie',
      total: 0,
      list: null
    })

    onMounted(() => {
      if (data.uri === '/panel/top10') {
        data.title = t('charts.top10.title')
        data.type = 'button'
        data.docPath = t('charts.top10.docPath')
        data.placeholder1 = t('charts.top10.placeholder1')
        data.placeholder2 = t('charts.top10.placeholder2')
      } else if (data.uri === '/panel/daily') {
        data.title = t('charts.daily.title')
        data.type = 'date'
        data.docPath = t('charts.daily.docPath')
        data.placeholder1 = t('charts.daily.placeholder1')
      }

      // 动态计算图表窗口高度
      data.chartAreaHeight = contentRef.value.offsetHeight - 205

      if (data.uri === '/panel/top10') {
        setTimeout(() => { // 不延时出不来统计图，上面高度计算问题
          top10Total()
        })
      }
    })

    // 总发言
    const top10Total = () => {
      Dialog({ type: 'loading' })
      data.chartTitle = t('charts.top10TotalApi.title')
      echarts.init(chartRef.value).dispose()

      // 请求数据
      data.total = 0
      data.list = null
      top10TotalApi().then(succ => {
        if (succ.code === 10000) {
          data.total = succ.data.total
          if (succ.data.list.length !== 0) {
            data.list = succ.data.list
            setTimeout(() => {
              initTop10()
            })
          }
        }
      }).finally(() => {
        Dialog({ remove: true })
      })
    }
    // 当天总发言
    const top10Day = () => {
      Dialog({ type: 'loading' })
      data.chartTitle = t('charts.top10DayApi.title')
      echarts.init(chartRef.value).dispose()
      const date = new Date()

      // 请求数据
      data.total = 0
      data.list = null
      top10DayApi({
        date: date.getFullYear() + '-' + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
      }).then(succ => {
        if (succ.code === 10000) {
          data.total = succ.data.total
          if (succ.data.list.length !== 0) {
            data.list = succ.data.list
            setTimeout(() => {
              initTop10()
            })
          }
        }
      }).finally(() => {
        Dialog({ remove: true })
      })
    }
    // daily 页
    const daily = date => {
      Dialog({ type: 'loading' })
      echarts.init(chartRef.value).dispose()

      // 请求数据
      data.total = 0
      data.list = null
      groupDailyApi({
        date: date
      }).then(succ => {
        if (succ.code === 10000) {
          delete succ.data.date
          data.list = succ.data
          for (const key in succ.data) {
            data.total += data.list[key]
          }
          setTimeout(() => {
            initDaily()
          })
        }
      }).finally(() => {
        Dialog({ remove: true })
      })
    }
    // 切换图标样式
    const swt = () => {
      data.chartType = data.chartType === 'pie' ? 'hist' : 'pie'
      echarts.init(chartRef.value).dispose()
      if (data.uri === '/panel/top10') {
        initTop10()
      } else if (data.uri === '/panel/daily') {
        initDaily()
      }
    }

    const initTop10 = () => {
      const abscissa = []
      const xData = []

      // 数据格式化
      for (let i = 0; i < data.list.length; i++) {
        abscissa.push(data.list[i].nickname)
        if (data.chartType === 'pie') { // 饼图数据结构
          xData.push({
            name: data.list[i].nickname,
            value: data.list[i].total
          })
        } else if (data.chartType === 'hist') { // 直方图数据结构
          xData.push(parseInt(data.list[i].total))
        }
      }

      // 构建图表
      if (data.chartType === 'pie') {
        echarts.init(chartRef.value, null, { renderer: 'svg' }).setOption(pieOption(abscissa, xData))
      } else if (data.chartType === 'hist') {
        echarts.init(chartRef.value, null, { renderer: 'svg' }).setOption(histOption(abscissa, xData, t('charts.histOption.top10.xName')))
      }
    }

    const initDaily = () => {
      const abscissa = []
      const xData = []

      // 数据格式化
      for (const key in data.list) {
        abscissa.push(key.replace('t', 'T'))
        if (data.chartType === 'pie') { // 饼图数据结构
          xData.push({
            name: key.replace('t', 'T'),
            value: data.list[key]
          })
        } else if (data.chartType === 'hist') { // 直方图数据结构
          xData.push(parseInt(data.list[key]))
        }
      }

      // 构建图表
      if (data.chartType === 'pie') {
        echarts.init(chartRef.value, null, { renderer: 'svg' }).setOption(pieOption(abscissa, xData))
      } else if (data.chartType === 'hist') {
        echarts.init(chartRef.value, null, { renderer: 'svg' }).setOption(histOption(abscissa, xData, t('charts.histOption.daily.xName')))
      }
    }

    const pieOption = (abscissa, data) => {
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            saveAsImage: {}
          }
        },
        legend: {
          orient: 'vertical',
          top: 0,
          left: 0,
          data: abscissa
        },
        series: [
          {
            // name: t('charts.pieOption.series.name'),
            type: 'pie',
            radius: '75%',
            center: ['50%', '50%'],
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }

    const histOption = (abscissa, data, xName) => {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['bar', 'line'] },
            saveAsImage: {}
          }
        },
        xAxis: [
          {
            type: 'category',
            name: xName,
            data: abscissa,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            name: t('charts.histOption.yAxis.name'),
            type: 'value',
            min: 0,
            axisLabel: {
              formatter: '{value} ' + t('charts.histOption.yAxis.axisLabel.formatter')
            }
          }
        ],
        series: [
          {
            name: t('charts.histOption.yAxis.name'),
            type: 'bar',
            data: data,
            itemStyle: {
              normal: {
                color: '#e54d26'
              }
            }
          }
        ]
      }
    }

    return { ...toRefs(data), t, contentRef, chartRef, swt, top10Total, top10Day, daily }
  }
}
</script>

<style lang="scss" scoped>
.charts {
  &-content {
    &-in {
      .chart-title {
        display: table;
        width: 100%;
        span {
          display: table-cell;
          vertical-align: middle;
        }
        .text {
          font-size: 18px;
          font-weight: bold;
        }
        .switch {
          text-align: right;
          em {
            color: #fff;
            display: inline-block;
            line-height: 25px;
            cursor: pointer;
            padding: 0 8px;
            font-size: 12px;
            border-radius: 6px;
            text-align: center;
            background: #e54d26;
          }
        }
      }
      .chart {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}
</style>
